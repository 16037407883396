import React, {useEffect, useState} from 'react';
import styles from './ReplaceImage.module.scss';
import CheckboxNew from '../../shared/checkboxNew';
import {connect} from 'react-redux';
import ImageUploadComponent from '../../shared/imageUpload';
import {
  setReplacedImage,
  setButton_169_status,
  setButton_11_status,
  setReplacedImageUploaded,
  setImageData,
  setDeletePreviousImage,
  setCroppedImageData11,
  setCroppedImageData169,
  setCroppedImage_16_9,
  setCroppedImage_1_1,
} from '../../../redux/actions/imageCrop/imageCropAction';
import {useHistory} from 'react-router-dom';
import {
  setDashboardNotificationCountApi,
  setNotificationListApi,
  setNotificationData,
  setImageReplaceState,
} from '../../../redux/actions/uiActions';
import {makeRequest} from '../../utils/APIsUtils/httpsUtils';
import Pills from '../../shared/pills';
import {
  makeMobilePillsArray,
  getImageTag,
  updatedImageDetails,
  makeImageData,
  setNewReplacedImage,
  ImageSaveNotification,
  replaceImageNotification,
  replaceMobileImage,
  handleTags,
  handleMobileImageName,
} from '../../utils/ContentManagementUtils/ReplaceImageModalUtils';

const NewImageUploadComponent = (props) => {
  const {
    id,
    imageChange,
    parentComponent,
    isImageUploaded,
    actionName,
    replacedImage,
    AddImageDetailOnClick,
  } = props;

  return (
    <div className={styles.right_section_wrapper}>
      <div className={styles.title_wrapper}>
        <div className={styles.title}>Upload New Image in the section below.</div>
      </div>
      {!isImageUploaded && actionName !== 'IMAGE_REPLACEMENT' && (
        <div className={styles.image_upload_wrapper}>
          <ImageUploadComponent
            id={id}
            imageChange={imageChange}
            parentComponent={parentComponent}
          />
        </div>
      )}

      {isImageUploaded && replacedImage?.image && (
        <>
          <div className={[styles.img_wrapper].join(' ')}>
            <img className={styles.replaced_img} src={replacedImage?.image} alt=""></img>
          </div>
          <div
            className={[styles.img_details_opt_wrapper].join(' ')}
            onClick={() => AddImageDetailOnClick()}
          >
            <div className={styles.info_text}>Add Image Details</div>
            <div className={styles.left_arrow_icon}></div>
          </div>
        </>
      )}
      {!isImageUploaded ? <div className={styles.tag_text}> 3MB max | .jpg, jpeg</div> : ''}
    </div>
  );
};

const ImageNameErroMessage = (props) => {
  const {isfileError, isInvalidImageName, mobileImageName} = props;
  return (
    <>
      {isfileError ? (
        <div className={styles.error_message}>
          Image Name already exists in the Library. Enter a different name to save changes.
        </div>
      ) : null}
      {isInvalidImageName() ? (
        <div className={styles.error_message}>
          {mobileImageName?.length > 30
            ? 'Image file name should be 30 characters or less.'
            : 'Need 3 or more characters. Valid characters include A-z, 0-9, ., :, ?, !, -, “ ”, ‘’ '}
        </div>
      ) : null}
    </>
  );
};

const AddTagComponent = (props) => {
  const {
    selectedKeyword,
    handleKeywordInput,
    isKeywordDropdown,
    keywordSuggestion,
    handleSelectedKeyword,
  } = props;
  return (
    <>
      <input
        type={'text'}
        placeholder={'Add Keywords and Enter'}
        className={[
          styles.input,
          selectedKeyword?.length > 0 ? styles.active : '',
          styles.ip2,
        ].join(' ')}
        onChange={(e) => {
          handleKeywordInput(e.target.value);
        }}
        value={selectedKeyword.title}
      />
      {isKeywordDropdown ? (
        <div className={styles.dropdown_container}>
          {keywordSuggestion.length > 0 ? (
            <div className={styles.list_wrapper}>
              {keywordSuggestion &&
                keywordSuggestion.map((item, index) => (
                  <div
                    key={index}
                    className={styles.list}
                    onClick={() => handleSelectedKeyword(item)}
                  >
                    {item.title}
                  </div>
                ))}
            </div>
          ) : (
            <div className={styles.list_wrapper}>
              <div className={styles.list}>No results found</div>
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};

const ImageDetailsComponent = (props) => {
  const {
    isImageUploaded,
    replacedImage,
    handleMobileImageName,
    mobileImageName,
    isfileError,
    handleDescriptionInput,
    mobileDescription,
    selectedKeyword,
    handleKeywordInput,
    isKeywordDropdown,
    keywordSuggestion,
    handleSelectedKeyword,
    handleAddTags,
    mobilePillsArray,
    handleDeletePill,
    regexName,
    imageLocalList,
    setFileError,
    setImageData,
    setMobileImageName,
    image_data,
  } = props;
  const isInvalidImageName = () => {
    return (
      mobileImageName?.length > 0 && (mobileImageName?.length < 3 || mobileImageName?.length > 30)
    );
  };

  if (isImageUploaded && replacedImage?.image) {
    return (
      <div className={[styles.bottom_section, ''].join(' ')}>
        <div className={styles.title_wrapper_img}>
          <div className={styles.title}>Image Details</div>
        </div>
        <div className={styles.left_container}>
          <div
            className={[
              styles.info_container,
              isInvalidImageName() || isfileError ? styles.error_wrapper : '',
              ' ',
            ].join(' ')}
          >
            <div className={styles.label_wrapper}>
              <div className={styles.label_text}>File name</div>
              <div className={styles.tag_text}>Max 30 characters</div>
            </div>
            <div className={styles.form_field}>
              <div className={styles.input_wrapper}>
                <input
                  type={'text'}
                  placeholder={'Enter Image Name.jpg'}
                  className={styles.input}
                  onChange={(e) => {
                    e.target.value.length === 0 &&
                      handleMobileImageName(
                        '',
                        imageLocalList,
                        setFileError,
                        setImageData,
                        setMobileImageName,
                        image_data,
                      );
                    handleMobileImageName(
                      e.target.value?.toString()?.replaceAll(regexName, ''),
                      imageLocalList,
                      setFileError,
                      setImageData,
                      setMobileImageName,
                      image_data,
                    );
                  }}
                  value={mobileImageName?.toString()?.replaceAll(regexName, '')}
                />
                {isfileError || isInvalidImageName() ? (
                  <div className={styles.error_icon_red}></div>
                ) : null}
              </div>
            </div>
            <ImageNameErroMessage
              isfileError={isfileError}
              isInvalidImageName={isInvalidImageName}
              mobileImageName={mobileImageName}
            />
          </div>
          <div className={[styles.info_container, ' '].join(' ')}>
            <div className={styles.label_wrapper}>
              <div className={styles.label_text}>Description</div>
            </div>
            <div className={styles.form_field}>
              <div className={styles.input_wrapper}>
                <textarea
                  type={'text'}
                  placeholder={'Enter Image Description'}
                  className={styles.textarea}
                  onChange={(e) => handleDescriptionInput(e.target.value)}
                  value={mobileDescription}
                  contentEditable
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.right_container}>
          <div className={[styles.info_container, ' '].join(' ')}>
            <div className={styles.label_wrapper}>
              <div className={styles.label_text}>Add Tags</div>
            </div>
            <div className={styles.form_field}>
              <div className={styles.input_wrapper}>
                <div className={[styles.input_area, styles.active].join(' ')}>
                  <AddTagComponent
                    selectedKeyword={selectedKeyword}
                    handleKeywordInput={handleKeywordInput}
                    isKeywordDropdown={isKeywordDropdown}
                    keywordSuggestion={keywordSuggestion}
                    handleSelectedKeyword={handleSelectedKeyword}
                  />
                </div>

                <div
                  className={[styles.add_btn, selectedKeyword.title ? styles.selected : ''].join(
                    ' ',
                  )}
                  onClick={() => handleAddTags()}
                >
                  <span className={styles.plus_sign}></span> Add
                </div>
              </div>
            </div>
          </div>
          <div className={[styles.pills_container, 'col-xl-12 col-lg-12 pull-left'].join(' ')}>
            <Pills
              pillData={mobilePillsArray ? mobilePillsArray : []}
              isClose={true}
              isBold={false}
              onClosePill={handleDeletePill}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

const MobileImageCroppingComponent = (props) => {
  const {isImageUploaded, actionName} = props;
  if (!isImageUploaded && actionName !== 'IMAGE_REPLACEMENT') {
    return (
      <div className={styles.warning_section}>
        <div className={styles.message_section}>
          <div className={[styles.heading, styles.mb_5].join(' ')}>
            Image cropping is unavailable on mobile.
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

const ErrorNotification = (props) => {
  const {
    setNotificationData,
    image_data,
    enableReplaceButton,
    replacedImage,
    handleOnClickReplaceImageMobile,
    createImageAsset,
    isMobileFileNameInvalid,
    imageReplaceState
  } = props;
  return (
    <div
      onClick={() => {
        if (isMobileFileNameInvalid()) {
          setNotificationData({
            show: true, // boolean to show-hide the notification. At the time of hiding the notification, pass only show: false. No other params are reqd
            title: 'File Name Required',
            description: 'Image cannot be replaced without adding Image filename.',
            type: 'FAILURE',
          });
        } else if (
          image_data?.imageName &&
          image_data?.imageDescription &&
          image_data?.pillsData?.length > 0
        ) {
          // escaping conditions.
        } else if (!enableReplaceButton()) {
          replacedImage?.imgID
            ? ''
            : setNotificationData({
                show: true, // boolean to show-hide the notification. At the time of hiding the notification, pass only show: false. No other params are reqd
                title: 'Image Details missing',
                description:
                  'Image cannot be replaced without adding Image details. Click on Image Details to Continue.',
                type: 'FAILURE',
              });
        }
      }}
    >
      <button
        className={[
          styles.btn,
          styles.btn1,
          enableReplaceButton()
            ? styles.active
            : image_data?.imageName?.length >= 3 &&
              image_data?.imageDescription &&
              image_data?.pillsData?.length > 0 &&
              !imageReplaceState
            ? styles.active
            : styles.disabled,
        ].join(' ')}
        onClick={async () => {
          if (enableReplaceButton()) {
            handleOnClickReplaceImageMobile();
          } else {
            await createImageAsset();
          }
        }}
      >
        Replace Image
      </button>
    </div>
  );
};

const ReplaceImageModalComponent = (props) => {
  const router = useHistory();
  const {query = {}} = router.location;
  const {actionName} = query;

  const {
    closeReplaceImageModal,
    ImageDetail,
    replacedImage,
    setReplacedImage,
    setNotificationData,
    setButton_169_status,
    setButton_11_status,
    setReplacedImageUploaded,
    isImageUploaded,
    //setSearchImageList,
    setImageList,
    setImageListAll,
    isImageDetailDrawerUpdated,
    setImageDetailDrawerUpdated,
    setImageDetail,
    setImageInput,
    setDescriptionInput,
    setCourseIds,
    imageinput,
    handleImageClick,
    setImageData,
    pillsData,
    image_list_all,
    image_data,
    newCourseWizardState,
    imageReplaceState,
    setDashboardNotificationCountApi,
    setNotificationListApi,
    currentUserId,
    setDeletePreviousImage,
    deleteImageCheckbox,
    croppedImageData169,
    croppedImageData11,
    setCroppedImageData11,
    setCroppedImageData169,
    setCroppedImage_16_9,
    setCroppedImage_1_1,
    setImageReplaceState
  } = props;

  const allowedFileSize = 3 * 1000 * 1000; //Allowed File Size is 6 MB
  const allowedFileType = ['image/jpeg', 'image/jpg'];
  const {bannerImage} = ImageDetail;

  const [isDeletePrevious, setDeletePrevious] = useState(false);
  //const [newImageId, setNewImageId] = useState(null);
  const [newImageName, setNewImageName] = useState('');

  const [imageLocalList, setImageLocalList] = useState([]);
  const [keywordSuggestion, setKeywordSuggestion] = useState([]);
  const [KeywordSuggestionCopy, setKeywordSuggestionCopy] = useState([]);
  const [isfileError, setFileError] = useState(false);
  const [selectedKeyword, setSelectedKeyword] = useState({title: ''});
  const [isKeywordDropdown, setKeywordDropdown] = useState(false);

  const [newCreatedImageData, setNewCreatedImageData] = useState(null);

  const [mobileImageName, setMobileImageName] = useState('');
  const [mobileDescription, setMobileDescription] = useState('');

  const [mobilePillsArray, setMobilePillsArray] = useState(ImageDetail?.tags || []);

  const [mobileReplaceCall, setMobileReplaceCall] = useState(false);

  useEffect(() => {
    const image_list_data =
      image_list_all?.length > 0
        ? image_list_all
        : JSON.parse(sessionStorage.getItem('imagelistStorage'));

    setImageLocalList(image_list_data);
    fetchKeywords();

    setMobilePillsArray(makeMobilePillsArray(mobilePillsArray));

    if (
      // actionName === "IMAGE_REPLACEMENT" &&
      replacedImage?.respNewlyCreatedImageDetail
    ) {
      setNewImageName(replacedImage?.respNewlyCreatedImageDetail?.data?.title);
      setReplacedImage({
        image: replacedImage?.image,
        imgID: replacedImage?.respNewlyCreatedImageDetail?.data?.id,
      });
      //setNewImageId(replacedImage?.respNewlyCreatedImageDetail?.data?.id);
    }
  }, []);

  useEffect(() => {
    makeImageData(image_data, setImageData, pillsData);
  }, [pillsData]);

  useEffect(() => {
    setNewReplacedImage(
      newCreatedImageData,
      mobileReplaceCall,
      setReplacedImage,
      replacedImage,
      replaceImageForMobile,
      handleOnClickReplaceImage,
    );

    setMobileReplaceCall(false);
  }, [newCreatedImageData]);

  const fetchImageList = async () => {
    const _endpoint = '/admin/api/v1/images';
    let dataFetchImagelist = await makeRequest({
      method: 'GET',
      endpoint: _endpoint,
    });
    if (dataFetchImagelist) {
      //setSearchImageList(dataFetchImagelist?.data);
      setImageList(dataFetchImagelist?.data);
      setImageListAll(dataFetchImagelist?.data);
      sessionStorage.setItem('imagelistStorage', JSON.stringify(dataFetchImagelist?.data));
    }
  };

  const fetchImageDetails = async (imageId) => {
    const _endpoint = `/admin/api/v1/images/${imageId}`;
    let dataImageDetail = await makeRequest({
      method: 'GET',
      endpoint: _endpoint,
    });
    ImageSaveNotification(
      dataImageDetail,
      handleImageClick,
      isImageDetailDrawerUpdated,
      setNotificationData,
      setImageDetailDrawerUpdated,
      setImageDetail,
      setImageInput,
      setDescriptionInput,
      setCourseIds,
      imageinput,
    );
  };

  const enableReplaceButton = () => {
    return mobileImageName?.length >= 3 && mobileDescription !== '' && mobilePillsArray.length > 0;
  };

  const isMobileFileNameInvalid = () => {
    mobileImageName === '' && mobileDescription !== '' && mobilePillsArray.length > 0;
  };

  useEffect(() => {
    setDeletePrevious(deleteImageCheckbox);
  }, [deleteImageCheckbox]);

  const fetchKeywords = async () => {
    const _endpoint = '/api/v1/keywords';
    let dataFetchKeywords = await makeRequest({
      method: 'GET',
      endpoint: _endpoint,
    });
    if (dataFetchKeywords) {
      setKeywordSuggestion(dataFetchKeywords?.data);
      setKeywordSuggestionCopy(dataFetchKeywords?.data);
    }
  };

  const replaceImageForMobile = async (newId) => {
    let payload = replaceImageNotification(
      newImageName,
      isDeletePrevious,
      setNotificationData,
      ImageDetail,
      newId,
    );

    // image list fetch
    await fetchImageList();
    const _endpoint = '/admin/api/v1/images/replace';
    let dataReplaceImageForMob = await makeRequest({
      method: 'POST',
      endpoint: _endpoint,
      body: payload,
    });

    replaceMobileImage(
      dataReplaceImageForMob,
      closeReplaceImageModal,
      setImageData,
      setReplacedImage,
      setReplacedImageUploaded,
      setNotificationListApi,
      setDashboardNotificationCountApi,
      currentUserId,
      fetchImageList,
      router,
    );
    // drawer image fetch
    if (newId) {
      await fetchImageDetails(newId);
      await fetchImageList();
    }
    setReplacedImageUploaded(false);
    setMobileImageName('');
    setMobileDescription('');
    setMobilePillsArray([]);
    setNewCreatedImageData(null);
  };

  const handleOnClickReplaceImageMobile = async () => {
    // let _tags = "";

    setMobileReplaceCall(true);
    let _tags = getImageTag(mobilePillsArray);

    const formData = new FormData();
    formData.append('IMAGE_16_9', replacedImage?.imgFile);
    formData.append('IMAGE_1_1', replacedImage?.imgFile);
    formData.append('fileName', mobileImageName);
    formData.append('description', mobileDescription);
    formData.append('tags', _tags);
    await updateImageDetails(formData);
  };
  const dataURLtoFile = (dataurl, filename) => {
    const array = dataurl.split(',');
    const match = array[0].match(/:(.*?);/);
    if (!match || match.length < 1) {
      throw new Error('Invalid dataUrl');
    }
    const mime = match[1];
    const bstr = window.atob(array[1]);
    let n = bstr.length;
    const u8arry = new Uint8Array(n);
    while (n--) {
      u8arry[n] = bstr.codePointAt(n);
    }
    return new File([u8arry], filename, {type: mime});
  };
  // Utility method to resize image to 16:9 OR 1:1 aspect ratio if it is uploaded without being cropped
  const getDefaultDimensionImg = (aspectRatio, selectedImage_16_9_Backup) => {
    return new Promise((resolve) => {
      const imageFile = selectedImage_16_9_Backup?.imgFile;
      const fileReader = new FileReader();
      fileReader.readAsDataURL(imageFile);
      fileReader.onload = (fileReaderEvent) => {
        const imageAsBase64 = fileReaderEvent.target.result;
        const image = document.createElement('img');
        image.src = imageAsBase64;
        const imageHeight = image.height;
        const imageWidth = image.width;
        const minDimension =
          imageHeight >= imageWidth ? parseInt(imageWidth) : parseInt(imageHeight);

        // Specify Resize Image Height-Width based on aspect ratio.
        const resizeImageHeight = minDimension;
        const resizeImageWidth =
          aspectRatio == '16:9' ? parseInt((minDimension / 9) * 16) : minDimension;

        const canvas = document.createElement('canvas');
        canvas.width = resizeImageWidth;
        canvas.height = resizeImageHeight;

        const context = canvas.getContext('2d', {alpha: false});
        context.drawImage(image, 0, 0, canvas.width, canvas.height);

        const resizedImageAsBase64 = canvas.toDataURL('image/jpeg');

        //...............................................................................................
        let uploadedImage = selectedImage_16_9_Backup?.imgFile;
        const regexImageNameForAPI = /[^\u4E00-\u9FFF\u3400-\u4DFF\uF900-\uFAFFa-zA-Z0-9]/g;
        let _fileName = '';
        _fileName = imageinput;
        let _fileType = selectedImage_16_9_Backup.imgFile.name.substring(
          selectedImage_16_9_Backup.imgFile.name.lastIndexOf('.'),
          selectedImage_16_9_Backup.imgFile.name.length,
        );
        _fileName = _fileName.replaceAll(regexImageNameForAPI, '');
        _fileName = _fileName + _fileType;
        setImageInput(_fileName);
        let blob = selectedImage_16_9_Backup.imgFile.slice(
          0,
          selectedImage_16_9_Backup.imgFile.size,
          uploadedImage.type,
        );
        uploadedImage = new File([blob], _fileName, {type: uploadedImage.type});
        //...............................................................................................

        const resizedImageFile = dataURLtoFile(resizedImageAsBase64, uploadedImage?.name);
        const responseObject = {
          image: resizedImageAsBase64,
          imgFile: resizedImageFile,
        };
        resolve(responseObject);
      };
    });
  };
  const createImageAsset = async () => {
    if(!imageReplaceState){
      setImageReplaceState(true);
      const promiseArray = [];
    if (croppedImageData169 === null || croppedImageData169 === undefined) {
      promiseArray.push(getDefaultDimensionImg('16:9', replacedImage));
    } else {
      promiseArray.push(getDefaultDimensionImg('16:9', croppedImageData169));
    }
    if (croppedImageData11 === null || croppedImageData11 === undefined) {
      promiseArray.push(getDefaultDimensionImg('1:1', replacedImage));
    } else {
      promiseArray.push(getDefaultDimensionImg('1:1', croppedImageData11));
    }
    Promise.all(promiseArray).then(async (responses) => {
      // let uploadedImage = selectedImage_16_9_Backup?.imgFile
      // const regexImageNameForAPI = /[^\u4E00-\u9FFF\u3400-\u4DFF\uF900-\uFAFFa-zA-Z0-9]/g;
      // let _fileName = ""
      // _fileName = imageinput
      // let _fileType = selectedImage_16_9_Backup.imgFile.name.substring(selectedImage_16_9_Backup.imgFile.name.lastIndexOf("."), selectedImage_16_9_Backup.imgFile.name.length)
      // _fileName = _fileName.replaceAll(regexImageNameForAPI, "")
      // _fileName = _fileName + _fileType
      // setImageInput(_fileName)
      // let blob = selectedImage_16_9_Backup.imgFile.slice(0, selectedImage_16_9_Backup.imgFile.size, uploadedImage.type);
      // uploadedImage = new File([blob], _fileName, {type: uploadedImage.type});
      const formData = new FormData();
      formData.append('IMAGE_16_9', responses[0]?.imgFile);
      formData.append('IMAGE_1_1', responses[1]?.imgFile);
      let imageTags = [];
      for (const item of image_data?.pillsData) {
        imageTags?.push(item?.title);
      }
      formData.append('fileName', image_data?.imageName);
      formData.append('description', image_data?.imageDescription);
      formData.append('tags', imageTags);
      await updateImageDetails(formData);
    });
    }
    
  };

  const handleOnClickReplaceImage = async (newId) => {
    if (isDeletePrevious === true) {
      //delete images notification
      setNotificationData({
        show: true, // boolean to show-hide the notification. At the time of hiding the notification, pass only show: false. No other params are reqd
        title: 'Image Replaced & Deleted',
        description: `${ImageDetail.fileName} has been replaced and deleted with ${image_data?.imageName}.`,
        type: 'SUCCESS',
      });
    } else {
      setNotificationData({
        show: true, // boolean to show-hide the notification. At the time of hiding the notification, pass only show: false. No other params are reqd
        title: 'Image Replaced',
        description: `${ImageDetail.fileName} has been replaced with ${image_data?.imageName}.`,
        type: 'SUCCESS',
      });
    }

    let payload = {
      previousImageId: ImageDetail?.imageId ? ImageDetail?.imageId : null,
      newImageId: newId ? newId : null,
      deletePrevious: isDeletePrevious,
    };

    const _endpoint = '/admin/api/v1/images/replace';
    let dataOnClickReplaceImage = await makeRequest({
      method: 'POST',
      endpoint: _endpoint,
      body: payload,
    });
    if (dataOnClickReplaceImage) {
      router.push({
        pathname: '/Contentmanagement',
        query: {actionName: ''},
      });
      setNotificationListApi();
      setDashboardNotificationCountApi(currentUserId);
      closeReplaceImageModal();
      setImageData({
        imageName: null,
        imageDescription: null,
        pillsData: null,
      });
      setReplacedImage(null);
      setImageReplaceState(false);
      setReplacedImageUploaded(false);
    }
    // image list fetch
    await fetchImageList();

    // drawer image fetch
    if (newId) {
      await fetchImageDetails(newId);
    }

    setReplacedImageUploaded(false);
    setMobileImageName('');
    setMobileDescription('');
    setMobilePillsArray([]);
    setNewCreatedImageData(null);

    setImageData({
      imageName: null,
      imageDescription: null,
      pillsData: null,
    });
    setCroppedImageData11(null);
    setCroppedImageData169(null);
    setCroppedImage_16_9(null);
    setCroppedImage_1_1(null);
  };

  const handleDeletePrevious = () => {
    setDeletePrevious(!isDeletePrevious);
  };
  const imageChange = (event) => {
    if (
      event.target.files &&
      event.target.files.length > 0 &&
      event.target.files[0].size <= allowedFileSize &&
      allowedFileType.includes(event.target.files[0].type)
    ) {
      setReplacedImageUploaded(true);
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        const image = reader.result;

        setReplacedImage({
          image,
          imgFile: event.target.files[0],
          imageDetail: ImageDetail,
          // imgID: replacedImage?.respNewlyCreatedImageDetail?.data?.id,
        });
        //setMobileImageName(event.target.files[0].name);
        setButton_169_status(1);
        setButton_11_status(1);
      });

      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const updateImageDetails = async (payload) => {
    const _endpoint = '/admin/api/v1/images';
    let dataUpdateImageDetails = await makeRequest({
      method: 'POST',
      endpoint: _endpoint,
      body: payload,
    });

    updatedImageDetails(
      dataUpdateImageDetails,
      setNotificationData,
      setNewCreatedImageData,
      replacedImage,
      setReplacedImage,
      newCourseWizardState,
    );
    setDashboardNotificationCountApi(currentUserId);
    setNotificationListApi();
  };

  function AddImageDetailOnClick() {
    setDeletePreviousImage(isDeletePrevious);
    
    setImageData({
      imageName: null,
      imageDescription: null,
      pillsData: null,
    });

    router.push({
      pathname: '/add-new-image',
      query: {actionName: 'IMAGE_REPLACEMENT'},
    });
  }

  const handleDescriptionInput = (value) => {
    if (value.length <= 500) {
      setMobileDescription(value);
      setImageData({
        ...image_data,
        imageDescription: value,
      });
    }
  };
  //const regexName = new RegExp("^[a-zA-Z0-9?:!\"'. -]*$");
  const regexName = /[^a-zA-Z0-9.?:!\"' -]/g;
  const handleKeywordInput = (value) => {
    setSelectedKeyword(value);
    if (value.length > 0) {
      setKeywordDropdown(true);
      const updatedKeywordSuggestion = keywordSuggestion.filter((item) => {
        return item.title.toLowerCase().search(value.toLowerCase()) !== -1;
      });
      setKeywordSuggestion(updatedKeywordSuggestion);
    } else {
      setKeywordDropdown(false);
      setKeywordSuggestion(KeywordSuggestionCopy);
    }
  };

  const handleSelectedKeyword = (item) => {
    setSelectedKeyword(item);
    setKeywordDropdown(false);
  };

  const handleAddTags = () => {
    handleTags(
      selectedKeyword,
      mobilePillsArray,
      setMobilePillsArray,
      setImageData,
      setSelectedKeyword,
      image_data,
    );
  };

  const handleDeletePill = (item) => {
    let tempPills = [...mobilePillsArray];
    const index = tempPills.indexOf(item);
    if (index > -1) {
      tempPills.splice(index, 1); // 2nd parameter means remove one item only
      // const updatedPills = keywordSuggestion.filter((item) => {
      //   return item.title.toLowerCase().search(value.toLowerCase()) !== -1;
      // });
      setMobilePillsArray(tempPills);
      setImageData({
        ...image_data,
        pillsData: tempPills,
      });
    }
  };

  return (
    <React.Fragment>
      <div className={[styles.modal_wrapper].join(' ')}>
        {/* <div className={styles.replace_img_modal}> */}
        <div
          className={styles.close_wrapper}
          onClick={() => {
            closeReplaceImageModal();
            setImageData({
              imageName: null,
              imageDescription: null,
              pillsData: null,
            });
            setCroppedImageData11(null);
            setCroppedImageData169(null);
            setCroppedImage_16_9(null);
            setCroppedImage_1_1(null);
            setReplacedImage(null);
            setReplacedImageUploaded(false);
          }}
        >
          <div className={styles.close_icon} />
        </div>
        <div className={styles.header_wrapper}>
          <div className={styles.heading}>
            Replace Image
            {/* {!isDeleteRecord ? "Confirm Delete" : "Delete user group"} */}
          </div>
        </div>
        <div className={styles.modal_middle_body}>
          <div className={styles.top_inner_wrapper}>
            <div className={styles.left_section_wrapper}>
              <div className={styles.title_wrapper}>
                <div className={styles.title}>Current Image</div>
              </div>
              <img className={styles.current_img} src={bannerImage} alt=""></img>

              <div className={styles.checkbox_container}>
                <CheckboxNew
                  mandateCheck={true}
                  isChecked={isDeletePrevious}
                  handleCheckClick={() => {
                    handleDeletePrevious();
                  }}
                  isDisabled={false}
                />
                <div className={styles.checkbox_note}>Delete previous Image post Replace Image</div>
              </div>
            </div>
            <NewImageUploadComponent
              id="imageUpload"
              imageChange={imageChange}
              parentComponent="REPLACE_IMAGE_MODAL"
              isImageUploaded={isImageUploaded}
              actionName={actionName}
              replacedImage={replacedImage}
              AddImageDetailOnClick={AddImageDetailOnClick}
            />
          </div>
          {/* Bottom Section Of Page Start*/}

          <ImageDetailsComponent
            isImageUploaded={isImageUploaded}
            replacedImage={replacedImage}
            handleMobileImageName={handleMobileImageName}
            mobileImageName={mobileImageName}
            isfileError={isfileError}
            handleDescriptionInput={handleDescriptionInput}
            mobileDescription={mobileDescription}
            selectedKeyword={selectedKeyword}
            handleKeywordInput={handleKeywordInput}
            isKeywordDropdown={isKeywordDropdown}
            keywordSuggestion={keywordSuggestion}
            handleSelectedKeyword={handleSelectedKeyword}
            handleAddTags={handleAddTags}
            mobilePillsArray={mobilePillsArray}
            handleDeletePill={handleDeletePill}
            regexName={regexName}
            imageLocalList={imageLocalList}
            setFileError={setFileError}
            setImageData={setImageData}
            setMobileImageName={setMobileImageName}
            image_data={image_data}
          />

          <MobileImageCroppingComponent isImageUploaded={isImageUploaded} actionName={actionName} />
        </div>

        <div className={styles.btn_wrapper}>
          <button
            className={[styles.btn, styles.btn2].join(' ')}
            onClick={() => {
              closeReplaceImageModal();
              setImageData({
                imageName: null,
                imageDescription: null,
                pillsData: null,
              });
              setReplacedImage(null);
              setReplacedImageUploaded(false);
              router.push({
                query: {actionName: ''},
              });
            }}
          >
            Cancel
          </button>
          <ErrorNotification
            setNotificationData={setNotificationData}
            image_data={image_data}
            enableReplaceButton={enableReplaceButton}
            replacedImage={replacedImage}
            handleOnClickReplaceImageMobile={handleOnClickReplaceImageMobile}
            createImageAsset={createImageAsset}
            isMobileFileNameInvalid={isMobileFileNameInvalid}
            imageReplaceState={imageReplaceState}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

ReplaceImageModalComponent.propTypes = {};

const mapStateToProps = (state) => ({
  croppedImageData: state,
  croppedImageData169: state?.croppedImage?.croppedImageData169,
  croppedImageData11: state?.croppedImage?.croppedImageData11,
  replacedImage: state && state.croppedImage && state.croppedImage.replacedImage,
  isImageUploaded: state && state.croppedImage && state.croppedImage.isImageUploaded,
  image_list_all: state && state.croppedImage && state.croppedImage.image_list_all,
  image_data: state?.croppedImage?.image_data,
  deleteImageCheckbox: state?.croppedImage?.deleteImageCheckbox,
  newCourseWizardState: state && state.courseState && state.courseState.newCourseWizardState,
  currentUserId: state && state.uiState && state.uiState.currentUserId,
  imageReplaceState: state && state.uiState && state.uiState.imageReplaceState
});

const mapDispatchToProps = (dispatch) => ({
  setReplacedImage: (options) => dispatch(setReplacedImage(options)),
  setNotificationData: (options) => dispatch(setNotificationData(options)),
  setButton_169_status: (options) => dispatch(setButton_169_status(options)),
  setDeletePreviousImage: (options) => dispatch(setDeletePreviousImage(options)),

  setCroppedImageData169: (options) => dispatch(setCroppedImageData169(options)),
  setCroppedImageData11: (options) => dispatch(setCroppedImageData11(options)),

  setCroppedImage_16_9: (options) => dispatch(setCroppedImage_16_9(options)),
  setCroppedImage_1_1: (options) => dispatch(setCroppedImage_1_1(options)),

  setButton_11_status: (options) => dispatch(setButton_11_status(options)),
  setReplacedImageUploaded: (options) => dispatch(setReplacedImageUploaded(options)),
  setImageData: (options) => dispatch(setImageData(options)),
  setDashboardNotificationCountApi: (options) =>
    dispatch(setDashboardNotificationCountApi(options)),
  setNotificationListApi: (options) => dispatch(setNotificationListApi(options)),
  setImageReplaceState :(options) => dispatch(setImageReplaceState(options))
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(ReplaceImageModalComponent));
