import React from "react"
import UserGroupForm from "./UserGroupForm"
import styles from "./EditCustomizedUserGroup.module.scss"

const EditCustomizedUserGroup = ({audienceGroupList, onCancelCb, onDeleteCb, setAudienceDetails}) => {
    return <div>
        <UserGroupForm audienceGroupList={audienceGroupList} onCancelCb = {onCancelCb} setAudienceDetails={setAudienceDetails}/>
        <button className={styles.deleteUserGroup} onClick={onDeleteCb}><span className={styles.deleteIcon}></span>Delete User Group</button>
    </div>
}

export default EditCustomizedUserGroup


