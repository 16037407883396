
export const validateCSVData = (data) => {

    if (data.length > 5000) {
        return { status: false, type: 'WARNING', title: 'User Group List Failed to Upload', description: `Updated data exceeds max record of 5000 records`};
    }

    for (let i = 0; i < data.length; i++) {
      const rowdata = Object.values(data[i]);
      const rowNum = i + 2;

      // Validate AFF Code (must be exactly 3 digits, no spaces)
      if (!/^\d{3}$/.test(rowdata[0])) {
        return { status: false, type: 'WARNING', title: 'File Format Error', description: `Failed to upload due to data error at row ${rowNum}.`};
      }

      // Validate ABO Number (must be a non-null number with no spaces)
      if (!/^\d+$/.test(rowdata[1])) {
        return { status: false, type: 'WARNING', title: 'File Format Error', description: `Failed to upload due to data error at row ${rowNum}.`};
      }
    }

    return {status: true};
  };