// utils/drawerUtils.js
import { makeCustomUGRequest } from "../components/utils/APIsUtils/httpsUtils";
import { fetchCustomUserGroupList } from "../redux/actions/userGroupManagementAction";

export const deleteCustomUserGroup = async (drawerDetails, drawerClose, setIsConfirm, dispatch) => {
  const params = {
    endpoint: `/user-groups/${drawerDetails?.audienceGroupId}`,
    method: 'delete',
  };

  try {
    const response = await makeCustomUGRequest(params);
    if (response !== 'ERR_NETWORK') {
      setIsConfirm(false); // Close the confirmation dialog
      drawerClose(false); // Close the drawer
      dispatch(fetchCustomUserGroupList())
    } else {
      console.error('Network error occurred');
    }
  } catch (error) {
    console.error('Error while deleting user group:', error);
  }
};

export const downloadCustomUserList = async (drawerDetails) => {
  const params = {
    endpoint: `/user-groups/${drawerDetails?.audienceGroupId}/members`,
    method: 'get',
    responseType: 'blob',
    customHeaders: {
      Accept: 'text/csv',
    },
  };

  try {
    const response = await makeCustomUGRequest(params);
    if (response) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${drawerDetails?.audienceGrpName}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  } catch (error) {
    console.error('Error while downloading user group list:', error);
  }
};
