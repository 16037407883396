import React from 'react'
import styles from './Input.module.scss'

const InputField = (props) => {
    const { inputValue = '', marginLeft = 0, flexProperty = 0, disabled = false , label = '', inputInfo = '', onChangeCb = () => {}, isError = false, errorMessage = ''} = props;
    return (
      <div className={styles.input_container}>
        <div className={styles.labelContainer}>
          {label && <label>{label}</label>}
          {inputInfo && <span>{inputInfo}</span>}
        </div>
        <div className={styles.input_wrapper}>
          <input
            type="text"
            className={[styles.inputField, disabled ? styles.disabled : styles.active, isError ? styles.error : ''].join(' ')}
            style={{ marginLeft: marginLeft, flex: flexProperty }}
            value={inputValue}
            disabled={disabled}
            onChange={onChangeCb}
          />
          {isError ? <div className={styles.error_icon_red}></div> : null}
        </div>
        {isError && <div className={styles.error_message}>{errorMessage}</div>}
      </div>
    );
  };

  export default InputField