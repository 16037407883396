import { SET_CUSTOM_USER_GROUP_LIST, SET_EDIT_USER_GROUP_DATA, SET_IS_CREATE_USER_GROUP, SET_IS_CUSTOM_USER_GROUP, SET_IS_EDIT_USER_GROUP } from "../actions/userGroupManagementAction";

const initialState = {
    isCreateUserGroup: false,
    isCustomUserGroup: false,
    isEditUserGroup: false,
    editUserGroupData: {
      userGroupName: '',
      userGroupDescription: '',
      userGroupId:''
    },
    customUserGroupList: []
};

export const userGroupManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_CREATE_USER_GROUP:
      return {
        ...state,
        isCreateUserGroup: action?.payload,
      };
    case SET_IS_EDIT_USER_GROUP:
      return {
          ...state,
          isEditUserGroup: action?.payload,
      };
    
    case SET_IS_CUSTOM_USER_GROUP:
      return {
        ...state,
        isCustomUserGroup: action?.payload,
      };

    case SET_EDIT_USER_GROUP_DATA:
      return {
        ...state,
        editUserGroupData: action?.payload,
      };
    
    case SET_CUSTOM_USER_GROUP_LIST:
      return {
        ...state,
        customUserGroupList: action?.payload,
      };
            
    default:
      return state;
  }
};
