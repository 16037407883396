import { makeCustomUGRequest } from "../../components/utils/APIsUtils/httpsUtils";

export const SET_IS_CREATE_USER_GROUP = 'SET_IS_CREATE_USER_GROUP';
export const SET_IS_CUSTOM_USER_GROUP = 'SET_IS_CUSTOM_USER_GROUP';
export const SET_IS_EDIT_USER_GROUP = 'SET_IS_EDIT_USER_GROUP';
export const SET_EDIT_USER_GROUP_DATA = 'SET_EDIT_USER_GROUP_DATA';
export const SET_CUSTOM_USER_GROUP_LIST = 'SET_CUSTOM_USER_GROUP_LIST';

export const setIsCreateUserGroup = (options) => {
  return {
    type: SET_IS_CREATE_USER_GROUP,
    payload: options,
  };
};

export const setIsEditUserGroup = (options) => {
  return {
      type: SET_IS_EDIT_USER_GROUP,
      payload: options,
  };
};

export const setIsCustomUserGroup = (options) => {
  return {
    type: SET_IS_CUSTOM_USER_GROUP,
    payload: options,
  };
};

export const setEditUserGroupData = (options) => {
  return {
    type: SET_EDIT_USER_GROUP_DATA,
    payload: options,
  };
};

export const setCustomUserGroupList = (options) => {
  return {
    type: SET_CUSTOM_USER_GROUP_LIST,
    payload: options,
  };
};

export const fetchCustomUserGroupList = () => {

  return async (dispatch) => {
    try {

      const response = await makeCustomUGRequest({method: 'get', endpoint : `/user-groups?sourceSystem=ACADEMY&adminGroup=${process.env.REACT_APP_CURRENT_REGION}&includeGroupDetails=true`});
      dispatch(setCustomUserGroupList(response?.data?.userGroups))
      
    } catch (error) {
      console.log('error', error);
      dispatch(setCustomUserGroupList([]));
    }
  };
};