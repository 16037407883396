import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import styles from '../CourseWizard.module.scss';
import AdaptFileModalComponent from '../AdaptFileModal';
import NoteCardComponent from '../../../shared/noteCard';
import CardNewComponent from '../../../shared/cardNewComponent';
import CheckboxNew from '../../../shared/checkboxNew';
import Pills from '../../../shared/pills';
import LoadingShimmerCustom from '../../../shared/LoadingShimmerCustom';
import TranslationLanguage from '../../../translationLanguages';
import CountrySelection from '../../../shared/countrySelection';
import {setCountryList, setLearningPath} from '../../../../redux/actions/uiActions';
import {connect} from 'react-redux';
import CountrySelectionHandleEvents from '../../../utils/CountrySelection/countrySelectionHandleEvents';
import EmptyScreen from '../EmptyScreen/EmptyScreen';

const CreateWizard = ({
  mainState,
  nextState,
  nextTranslation,
  nextUserGrp,
  openAdaptFileModal,
  adaptFileState,
  fileDataTemp,
  fileDataTempHindi,
  data,
  setOpenAdaptFileModal,
  openInNewTab,
  setFileData,
  regexNameChinese,
  regexNameHindi,
  handleDataFill,
  handleDataFillMultiLingual,
  addUserGroupList,
  adaptFileButtonStateHindi,
  deleteUserGroupList,
  disableStateUserManagement,
  handleDeleteUserTargetingGroups,
  handleDeleteUserGroups,
  disableClickBanner,
  handleFileUpload,
  adaptFileButtonState,
  uploadingFileButton,
  fileData,
  fileDataHindi,
  getEnableSaveAndExit,
  isCourseIDFetched,
  mainImageBackup,
  setImageData,
  disableImageLib,
  setThumbnailImage169,
  setCourseMode,
  setNewCourseData,
  setNewCourseWizardState,
  uploadImageFromDesktop,
  uploadNewImagePath,
  setNewThumbnailImageData,
  setNewImageData,
  setMainImageBackup,
  setCroppedImageData,
  setCroppedImage_16_9,
  setCroppedImage_1_1,
  setSelectedImage_16_9_Backup,
  setSelectedImage_1_1_Backup,
  thumbnailImage169,
  setPreviewAdaptFileFlag,
  previewAdaptFileCreateNewCourse,
  previewData,
  categories,
  categoryData,
  setCategoryData,
  skills,
  skillsRef,
  setPillsDataSkills,
  pillsDataSkills,
  pillsAddAudienceGroup,
  pillsDataKeyword,
  filterListById,
  handleAddUserTargetingGroups,
  setNotificationData,
  newCourseID,
  addUserGroupListCopy,
  publishButtonAfterPreview,
  keyword,
  keywordsRef,
  setPillsDataKeyword,
  SearchListComponent,
  isLoading,
  uploadingFileButtonHindi,
  replaceFile = false,
  setPrimaryCourseAdded = () => {},
  isPrimaryCourseAdded = false,
  countryList,
  setCountryList,
  remoteConfig = {},
  showPrimaryNotification,
  setShowPrimaryNotification = () => {},
  isDuplicateCourseName = false,
  missingFilesMessage,
  setLearningPath,
  courseCodeErrorMessage = ''
}) => {
  const router = useHistory();
  const {query = {}} = router.location;
  const [localState, setLocalState] = useState('');

  const regexVietnamese =
    /[^\u0900-\u097F\u00C0-\u00FF\u00C0-\u00FF\u3131-\uD79D\u4e00-\u9fa50-9a-zA-Z_àáãạảăắằẳẵặâấầẩẫàậấèéẹẻẽêềếểễệđìíĩỉịòóõọỏôốồổỗộơớờởỡợọóửùúũụủưứừửữựỳỵỷỹýÀÁÃẠẢĂẮẰẲẴẶÂẤẦẨẪẬÈÉẸẺẼÊỀẾỂỄỆĐÌÍĨỈỊÒÓÕỌỎÔỐỒỔỖỘƠỚỜỞỠỢÙÚŨỤỦƯỨỪỬỮỰỲỴỶỸÝ.。:\-.,'"$%^&|*()!?;`~#+-_ ?？:：!！\"”“'‘’ -]+/g;
  const regexNameEnglish2 =
    /[\u0900-\u097F\u00C0-\u00FF\u00C0-\u00FF\u3131-\uD79D\u4e00-\u9fa50-9a-zA-Z_àáãạảăắằẳẵặâấầẩẫậàấèéẹẻẽêềếểễệđìíĩỉịòóõọỏôốồổỗộơớờởỡợọóửùúũụủưứừửữựỳỵỷỹýÀÁÃẠẢĂẮẰẲẴẶÂẤẦẨẪẬÈÉẸẺẼÊỀẾỂỄỆĐÌÍĨỈỊÒÓÕỌỎÔỐỒỔỖỘƠỚỜỞỠỢÙÚŨỤỦƯỨỪỬỮỰỲỴỶỸÝ.。:\-.,'"$%^&|*()!?;`~#+-_ ?？:：!！\"”“'‘’ -]+/g;
  const regexNameNumber = /[^0-9]+/g;

  const [mainImage, setMainImage] = useState(null);
  const [nameErrorStateEnglish, setNameErrorStateEnglish] = useState(false);
  const [maxCharErrorMessageEnglish, setMaxCharErrorMessageEnglish] = useState(false);
  const [nameErrorStateDescription, setNameErrorStateDescription] = useState(false);
  const [maxCharErrorMessageDescription, setMaxCharErrorMessageDescription] = useState(false);
  const [nameErrorStateSpeaker, setNameErrorStateSpeaker] = useState(false);
  const [maxCharErrorMessageSpeaker, setMaxCharErrorMessageSpeaker] = useState(false);
  const [nameErrorStateDuration, setNameErrorStateDuration] = useState(false);
  const [maxCharErrorMessageDuration, setMaxCharErrorMessageDuration] = useState(false);
  const [configData, setConfigData] = useState({});

  useEffect(() => {
    const configData = JSON.parse(localStorage.getItem('configData'));
    setConfigData(configData);
  }, []);

  useEffect(() => {
    if (data?.contentCode?.toString()?.length > 60) {
      setMaxCharErrorMessageEnglish(true);
    } else {
      setMaxCharErrorMessageEnglish(false);
    }
  }, [data?.contentCode])

  const {updateCountryList, handleSelectAllCountry, handleClearAllCountry, handleCountrySelect} =
    CountrySelectionHandleEvents();

  useEffect(() => {
    if (
      (data?.thumbnailId == null || data?.thumbnailId == undefined) &&
      data?.thumbnail == undefined
    ) {
      setMainImage(null);
      setNewThumbnailImageData(null);
      //setMainImageBackup(null);
      setNewImageData(null);
    } else if (
      (data && data?.thumbnailId !== null) ||
      (data?.thumbnailId !== undefined && data?.thumbnail !== undefined) ||
      mainImageBackup !== undefined
    ) {
      setMainImage(mainImageBackup);
      // setMainImage(data?.thumbnail);
      // setMainImageBackup(data?.thumbnail);
      setNewThumbnailImageData(data?.thumbnail);
    } else if (data && data?.thumbnail && data?.thumbnail !== undefined) {
      setMainImage(data?.thumbnail);
      setNewThumbnailImageData(data?.thumbnail);
    }

    if (mainImageBackup == null || mainImageBackup == undefined) {
      setMainImageBackup(data?.thumbnail);
    }

    return () => {
      setMainImage(null);
      setNewThumbnailImageData(null);
      // setNewImageData(null);
      // setMainImageBackup(null);
    };
  }, [data]);

  const handleCountryClearAll = () => {
    const newCountryList = handleClearAllCountry(countryList);
    setCountryList(newCountryList);
  };
  const handleCountrySelectAll = () => {
    const newCountryList = handleSelectAllCountry(countryList);
    setCountryList(newCountryList);
  };

  const handleCountrySelectA = (country) => {
    const newCountryList = handleCountrySelect(country, countryList);
    setCountryList(newCountryList);
  };

  const getShimmerList = (count) => {
    let content = [];
    for (let i = 0; i < count; i++) {
      content.push(<LoadingShimmerCustom className={[styles.card_holder_ugm_section].join(' ')} />);
    }
    return content;
  };

  const checkifDataAvailable = () => {
    let flag = false;
    if (
      !data?.primaryTitle?.length ||
      !data?.primaryDescription?.length ||
      !data?.duration ||
      !data?.thumbnailId
    ) {
      // !isCourseIDFetched
      flag = true;
    } else {
      flag = false;
    }
    return flag;
  };

  const disablehostingBanner = () => {
    setNotificationData({
      show: true,
      type: 'FAILURE',
      title: 'No Action can be taken during File Uploading',
      description:
        'All the buttons are disabled when the file is uploading. Please wait for the file to upload to take any action.',
    });
  };

  const getUploadCourseBtnClassName = () => {
    // If uploadingFileButton is true, show the in-progress state
    if (uploadingFileButton) {
      return [styles.btn_wrapper_uaf, styles.inprogress_state].join(' ');
    }

    // Check if there's a name in fileDataTemp
    const hasFileData = Boolean(fileDataTemp?.name);

    // Check if save and exit conditions are met
    const canSaveAndExit = getEnableSaveAndExit({
      primaryTitle: data?.primaryTitle,
      primaryDescription: data?.primaryDescription,
    });

    // Check if all necessary data is available
    const hasValidData = Boolean(
      (data?.primarySpeaker ? data?.primarySpeaker?.length <= 30 : true) &&
        data?.primaryTitle &&
        data?.primaryDescription &&
        data?.duration &&
        data?.thumbnailId &&
        !isDuplicateCourseName,
    );

    // Check if the upload button should be disabled
    const isUploadButtonDisabled =
      true ||
      adaptFileButtonState ||
      uploadingFileButton ||
      isDuplicateCourseName ||
      data?.primarySpeaker?.length > 30 ||
      (uploadingFileButtonHindi &&
        !data?.primaryTitle &&
        !data?.primaryDescription &&
        !data?.duration &&
        !data?.thumbnailId);

    // Determine the className based on conditions
    if (
      (hasFileData ||
        (canSaveAndExit &&
          data?.duration &&
          data?.thumbnailId &&
          data?.primarySpeaker?.length <= 30) ||
        hasValidData) &&
      !uploadingFileButtonHindi
    ) {
      return [styles.btn_wrapper_uaf, styles.final_state].join(' ');
    }

    if (isUploadButtonDisabled) {
      return [styles.btn_wrapper_uaf, styles.btn_wrapper_uaf_disabled].join(' ');
    }

    // Default to the disabled state if none of the above conditions are met
    return [styles.btn_wrapper_uaf, styles.btn_wrapper_uaf_disabled].join(' ');
  };

  return (
    <>
      {/* Course Information */}
      {mainState && (
        <div className={[styles.inner_wrapper, styles.padding_class, ''].join(' ')}>
          <div className={styles.row_wrapper}>
            {openAdaptFileModal && (
              <AdaptFileModalComponent
                unzipState={adaptFileState}
                closeModal={() => {
                  setOpenAdaptFileModal(false);
                  //fetchSelectedCourse(data?.courseId);
                }}
                localState={configData?.primaryLanguage}
                openPreviewFile={() => {
                  openInNewTab();
                  //fetchSelectedCourse(data?.courseId);
                  // updateCourseDetails(data?.courseId,{adaptFileName:fileData?.name,courseState:data?.courseState
                  //   //  ,version :versionNum
                  // })
                  // })
                  localState == configData?.locale;
                }}
              />
            )}

            {/* Info container block */}
            <div className={styles.info_container}>
              <div
                className={[styles.inr_wrapper, styles.margin_zero, 'col-md-12 col-lg-12'].join(
                  ' ',
                )}
              >
                <div className={[styles.title_wrapper, styles.margin_zero].join(' ')}>
                  <div className={styles.title}>Course Information</div>
                  <div className={[styles.txt, styles.margin_zero].join(' ')}>
                    Enter Course Information in primary language to enable Uploading Course file.
                    <div className={styles.note_text}>
                      Note: Title, Description, Thumbnail Image and Course File are mandatory to
                      save the Course in Draft state and to move forward in the Create Course
                      wizard.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Info container block */}

            <div className={[styles.info_container].join(' ')}>
              <div className={[styles.inr_wrapper, 'col-md-12 col-lg-6'].join(' ')}>
                <div className={styles.title_wrapper}>
                  <div className={styles.title}>
                    Course Information ({configData?.primaryLanguage})*
                  </div>
                  {/* <div className={styles.txt}>
                      Nunc at neque consectetur odio aenean mattis in. Posuere vel et vestibulum
                      pharetra posuere tempus.
                    </div> */}
                </div>

                <div
                  className={[
                    styles.form_field,
                    styles.info_wrapper,
                    (maxCharErrorMessageEnglish && data?.primaryTitle?.length > 100) ||
                    isDuplicateCourseName
                      ? styles.error_wrapper_max_char
                      : ' ',
                  ].join(' ')}
                >
                  <div className={styles.label_wrapper}>
                    <div className={styles.label_text}>Course Title*</div>
                    <div className={styles.tag_text}>Max 100 characters</div>
                  </div>
                  <div className={styles.input_wrapper}>
                    {isLoading ? (
                      <LoadingShimmerCustom className={styles.input} />
                    ) : (
                      <input
                        type={'text'}
                        placeholder={'Enter Course Title'}
                        className={styles.input}
                        defaultValue={data && data?.primaryTitle}
                        value={data && data?.primaryTitle}
                        name="primaryTitle"
                        maxLength="101"
                        onChange={(e) => {
                          // e.target.value.length === 0 && handleDataFill(e.target.name, '');
                          // handleDataFill(e.target.name, e.target.value);
                          e.target.value.length === 0 &&
                            handleDataFill(e.target.name?.normalize('NFC'), '');

                          handleDataFill(
                            e?.target?.name?.normalize('NFC'),
                            e?.target?.value?.toString(),
                          );

                          if (e?.target?.value?.toString()) {
                            setNameErrorStateEnglish(true);
                          } else {
                            setNameErrorStateEnglish(false);
                          }
                          if (e.target.value?.toString()?.length > 100) {
                            setMaxCharErrorMessageEnglish(true);
                          } else {
                            setMaxCharErrorMessageEnglish(false);
                          }
                        }}
                      />
                    )}
                    {(data && data?.primaryTitle?.length < 0 && data?.primaryTitle?.length != 0) ||
                    data?.primaryTitle?.length > 100 ||
                    isDuplicateCourseName ? (
                      <div className={styles.error_icon_red}></div>
                    ) : null}
                  </div>

                  {isDuplicateCourseName && (
                    <div className={styles.error_message}>
                      The course name already exists. Please choose a different name.
                    </div>
                  )}

                  {data?.primaryTitle?.length > 100 ? (
                    <div className={styles.error_message}>Maximum character limit reached</div>
                  ) : null}
                </div>

                {/* <div className={[styles.form_field, styles.info_wrapper].join(' ')}>
                  <div className={styles.label_wrapper}>
                    <div className={styles.label_text}>Description*</div>
                    <div className={styles.tag_text}>Max 500 characters</div>
                  </div>
                  <div className={styles.input_wrapper}>
                    {isLoading ? (
                      <LoadingShimmerCustom className={styles.textarea} />
                    ) : (
                      <textarea
                        type={'text'}
                        name="primaryDescription"
                        maxLength="500"
                        placeholder={'Enter Course Description'}
                        defaultValue={data && data?.primaryDescription}
                        value={data && data?.primaryDescription}
                        className={styles.textarea}
                        onChange={(e) => handleDataFill(e.target.name, e.target.value)}
                        // onBlur={() => callCreateCourseAPI()}
                      />
                    )}
                  </div>
                </div> */}
                <div
                  className={[
                    styles.form_field,
                    styles.info_wrapper,
                    maxCharErrorMessageDescription && data?.primaryDescription?.length > 500
                      ? styles.error_wrapper_max_char
                      : ' ',
                  ].join(' ')}
                >
                  <div className={styles.label_wrapper}>
                    <div className={styles.label_text}>Description*</div>
                    <div className={styles.tag_text}>Max 500 characters</div>
                  </div>
                  <div className={styles.input_wrapper}>
                    {isLoading ? (
                      <LoadingShimmerCustom className={styles.textarea} />
                    ) : (
                      <textarea
                        type={'text'}
                        name="primaryDescription"
                        maxLength="501"
                        placeholder={'Enter Course Description'}
                        defaultValue={data && data?.primaryDescription}
                        value={data && data?.primaryDescription}
                        className={styles.textarea}
                        onChange={(e) => {
                          // handleDataFill(e.target.name, e.target.value)
                          e?.target?.value?.length === 0 &&
                            handleDataFill(e.target.name?.normalize('NFC'), '');

                          handleDataFill(
                            e?.target?.name?.normalize('NFC'),
                            e?.target?.value?.toString(),
                          );

                          if (e?.target?.value?.toString()) {
                            setNameErrorStateDescription(true);
                          } else {
                            setNameErrorStateDescription(false);
                          }
                          if (e?.target?.value?.toString()?.length > 500) {
                            setMaxCharErrorMessageDescription(true);
                          } else {
                            setMaxCharErrorMessageDescription(false);
                          }
                        }}
                        // onBlur={() => callCreateCourseAPI()}
                      />
                    )}
                    {(data?.primaryDescription?.length < 0 &&
                      data?.primaryDescription?.length != 0) ||
                    data?.primaryDescription?.length > 500 ? (
                      <div className={styles.error_icon_red}></div>
                    ) : null}
                  </div>
                  {data?.primaryDescription?.length > 500 ? (
                    <div className={styles.error_message}>Maximum character limit reached</div>
                  ) : null}
                </div>

                <div
                  className={[
                    styles.form_field,
                    styles.info_wrapper,
                    maxCharErrorMessageSpeaker ? styles.error_wrapper_max_char : ' ',
                  ].join(' ')}
                >
                  <div className={styles.label_wrapper}>
                    <div className={styles.label_text}>
                      Course Author/Speaker Name ({configData?.primaryLanguage})
                    </div>
                    <div className={styles.tag_text}>Max 30 characters</div>
                  </div>

                  <div className={styles.txt}>
                    Enter the Author or a Speaker Name of the particular course. This information is
                    visible to the Front End ABO besides the title of ‘Taught by’.
                  </div>

                  <div className={[styles.form_field, styles.info_wrapper].join(' ')}>
                    <div className={styles.input_wrapper}>
                      {isLoading ? (
                        <LoadingShimmerCustom className={styles.input} />
                      ) : (
                        <input
                          type={'text'}
                          name="primarySpeaker"
                          placeholder={'Enter Course Author/Speaker Name'}
                          defaultValue={(data && data?.author) || data?.primarySpeaker}
                          value={(data && data?.author) || data?.primarySpeaker}
                          className={styles.input}
                          maxLength="31"
                          onChange={(e) => {
                            // handleDataFill(e.target.name, e.target.value)
                            e.target.value.length === 0 &&
                              handleDataFill(e?.target?.name?.normalize('NFC'), '');

                            handleDataFill(
                              e?.target?.name?.normalize('NFC'),
                              e.target.value?.toString(),
                            );

                            if (e.target.value?.toString()) {
                              setNameErrorStateSpeaker(true);
                            } else {
                              setNameErrorStateSpeaker(false);
                            }
                            if (e.target.value?.toString()?.length > 30) {
                              setMaxCharErrorMessageSpeaker(true);
                            } else {
                              setMaxCharErrorMessageSpeaker(false);
                            }
                          }}
                        />
                      )}
                      {(data?.primarySpeaker?.length < 0 && data?.primarySpeaker?.length != 0) ||
                      data?.primarySpeaker?.length > 30 ? (
                        <div className={styles.error_icon_red}></div>
                      ) : null}
                    </div>
                  </div>

                  {data?.primarySpeaker?.length > 30 ? (
                    <div className={`${styles.error_message} p-0`}>
                      Maximum character limit reached
                    </div>
                  ) : null}
                </div>
                {/*
                <div className={[styles.form_field, styles.info_wrapper].join(' ')}>
                  <div className={styles.input_wrapper}>
                    {isLoading ? (
                      <LoadingShimmerCustom className={styles.input} />
                    ) : (
                      <input
                        type={'text'}
                        name="primarySpeaker"
                        placeholder={'Enter Course Author/Speaker Name'}
                        defaultValue={(data && data?.author) || data?.primarySpeaker}
                        value={(data && data?.author) || data?.primarySpeaker}
                        className={styles.input}
                        onChange={(e) => handleDataFill(e.target.name, e.target.value)}
                      />
                    )}
                  </div>
                </div> */}

                {/* Course runtime / Duration  */}
                <div className={[styles.course_time_wrapper, styles.padding_class].join(' ')}>
                  <div className={styles.txt}>Total Course Runtime*</div>
                  <div className={styles.time_spend_wrapper}>
                    {isLoading ? (
                      <LoadingShimmerCustom className={styles.hrs_box} />
                    ) : (
                      <input
                        type={'number'}
                        name="duration"
                        defaultValue={data?.duration === 0 ? '' : data && Number(data?.duration)}
                        value={data?.duration === 0 ? '' : data && Number(data?.duration)}
                        placeholder={'00'}
                        min="0"
                        max="25000"
                        className={styles.hrs_box}
                        onChange={(e) => handleDataFill(e.target.name, Number(e.target.value))}
                        onFocus={(e) => (e.target.placeholder = '')}
                        onBlur={(e) => (e.target.placeholder = '0')}
                      />
                    )}
                    <div className={styles.txt_xs}>Minutes</div>
                  </div>
                </div>
                {/* Course runtime / Duration  */}

                {/* Course file */}
                <div className={[styles.info_container, styles.course_adapt_wrapper, ''].join(' ')}>
                  {/* English File upload Starts */}
                  <div className={[styles.inr_wrapper].join(' ')}>
                    <div className={styles.title_wrapper}>
                      <div className={styles.inner_title_wrapper}>
                        <div className={styles.title}>
                          Course File ({configData?.primaryLanguage}
                          )*
                        </div>
                        <div className={[styles.tag_text, styles.padding_zero].join(' ')}>
                          {' '}
                          200MB max | .zip
                        </div>
                      </div>

                      <div className={[styles.txt, styles.course_txt].join(' ')}>
                        Upload the Course file from your Local Device. Post uploading, you can
                        preview the file by clicking on ‘Preview Course File’.
                      </div>
                      {/* <div className={[styles.txt, styles.course_txt].join(' ')}>
                        Upload an Course file of the course from your local device and Preview it by
                        clicking on ‘Preview Course file’ on top of the wizard.
                      </div> */}

                      <input
                        type={'file'}
                        // accept=".zip,.rar,.7z"
                        accept=".zip"
                        onChange={(event) => {
                          handleFileUpload(
                            event,
                            data?.id || data?.courseId,
                            configData?.locale,
                            data?.version ? Number(data?.version) : 0,
                          );
                        }}
                        hidden={true}
                        multiple={false}
                        id={'uploadFileCreate'}
                        disabled={
                          adaptFileButtonState || uploadingFileButton || uploadingFileButtonHindi
                        }
                      />
                      <label
                        htmlFor="uploadFileCreate"
                        style={{
                          display: 'block',
                          width: '100%',
                          marginBottom: '0',
                        }}
                        //  style={{pointerEvents: !isCourseIDFetched ? 'none' : ''}}
                      >
                        <div
                          className={getUploadCourseBtnClassName()}
                          onClick={() => {
                            if (checkifDataAvailable()) {
                              setNotificationData({
                                show: true,
                                type: 'FAILURE',
                                title: 'Upload Course file disabled.',
                                description:
                                  'Enter Language Specific Course Title and Description to enable ‘Upload Adapt file’ button',
                              });
                            }
                            if (uploadingFileButton || uploadingFileButtonHindi) {
                              disablehostingBanner();
                            }
                            setLocalState(configData?.locale);
                          }}
                        >
                          <div
                            className={styles.txt}
                            style={{
                              minHeight: 'fit-content',
                              marginBottom: '0',
                            }}
                          >
                            {uploadingFileButton && !replaceFile
                              ? 'Course File Uploading'
                              : uploadingFileButton && replaceFile
                              ? 'File Replacing'
                              : data?.adaptFileName
                              ? 'Replace Course File'
                              : 'Upload Course File'}
                          </div>
                          <div className={styles.upload_icon}></div>
                        </div>
                        {uploadingFileButton && (
                          <div className={styles.note_text}>
                            Note: Please do not navigate to any other page when the file is
                            uploading.
                          </div>
                        )}
                      </label>

                      {/* Upload Course File Hosting Faild Banner */}
                      {adaptFileState === 'FAILURE' ? (
                        <div className={styles.hosting_banner}>
                          <div className={`${styles.error_bar} ${styles.failure_bar}`}>
                            <div className={`${styles.left_icon} ${styles.failure_tick_icon}`} />
                            <div className={`${styles.text_wrapper} ${styles.failure_txt}`}>
                              <div className={styles.tagline}>{'File Hosting Failed'}</div>
                              <div
                                className={styles.description}
                              >{`Please Upload the Course file again.(ie: missing ${missingFilesMessage} and etc)`}</div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        data?.adaptFileName ? (
                          <div className={[styles.attachement_wrapper, ''].join(' ')}>
                            <div className={[styles.txt, styles.prev_adapt_file_name].join(' ')}>
                              {data?.adaptFileName}
                            </div>
                            <div
                              className={[styles.txt, styles.preview_adapt_text, styles.bold].join(
                                ' ',
                              )}
                              onClick={async () => {
                                setPreviewAdaptFileFlag(true);
                                await previewAdaptFileCreateNewCourse(configData?.locale);
                              }}
                            >
                              Preview File
                              <div className={styles.left_arrow_icon} />
                            </div>
                          </div>
                        ) : (
                          ''
                          // <div className={[styles.tag_text, styles.padding_zero].join(' ')}>
                          //   {' '}
                          //   100MB max | .zip
                          // </div>
                        )
                      )}

                    </div>
                  </div>
                  {/* English File upload Starts */}
                </div>
                {/* Course file */}

                {/* Course Code Start */}
                <div
                  className={[
                    styles.form_field,
                    styles.info_wrapper,
                    (courseCodeErrorMessage?.length > 0)
                      ? styles.error_wrapper_max_char
                      : ' ',
                  ].join(' ')}
                >
                  <div className={styles.label_wrapper}>
                    <div className={styles.label_text}>Course Code</div>
                    <div className={styles.tag_text}>Max 60 characters</div>
                  </div>
                  <div className={styles.input_wrapper}>
                    {isLoading ? (
                      <LoadingShimmerCustom className={styles.input} />
                    ) : (
                      <input
                        type={'text'}
                        placeholder={'Enter Course Title'}
                        className={styles.input}
                        defaultValue={data && data?.contentCode}
                        value={data && data?.contentCode}
                        name="contentCode"
                        maxLength="61"
                        onChange={(e) => {
                          // e.target.value.length === 0 && handleDataFill(e.target.name, '');
                          // handleDataFill(e.target.name, e.target.value);
                          e.target.value.length === 0 &&
                            handleDataFill(e.target.name?.normalize('NFC'), '');

                          handleDataFill(
                            e?.target?.name?.normalize('NFC'),
                            e?.target?.value?.toString(),
                          );
                        }}
                      />
                    )}
                    {courseCodeErrorMessage?.length > 0 ? <div className={styles.error_icon_red}></div> : null}
                  </div>

                  {courseCodeErrorMessage?.length > 0 ? (
                    <div className={styles.error_message}>{courseCodeErrorMessage}</div>
                  ) : null}

                </div>
                {/* Course Code End */}
              </div>

              {/* Information Starts */}
              <div className={[styles.inr_wrapper, 'col-md-12 col-lg-6', ''].join(' ')}>
                {/* Image Thumbnail Starts */}
                <div className={[styles.inr_wrapper, styles.thumbnail_wrapper].join(' ')}>
                  <div className={styles.title_wrapper}>
                    <div className={styles.title}>Thumbnail Image*</div>
                    <div className={styles.txt}>
                      Add a Thumbnail Image to this Course by choosing an Image from the ‘Image
                      Library’ or Add a New Image from your Local Device.
                    </div>
                  </div>
                  {!mainImageBackup && !isLoading ? (
                    <div
                      className={[
                        styles.pre_browse_wrapper,
                        uploadingFileButton ? styles.disabled : '',
                        styles.uploadig_state,
                        '',
                      ].join(' ')}
                    >
                      <div className={styles.browse_img_txt_wrapper}>
                        <div className={styles.browse_img}></div>
                      </div>

                      <input
                        type={'file'}
                        accept=".jpg,.jpeg"
                        onChange={(event) => {
                          setCourseMode('FROM_COURSE_WIZARD');
                          setLearningPath('');
                          setImageData({
                            imageName: null,
                            imageDescription: null,
                            pillsData: null,
                          });
                          uploadImageFromDesktop(event, event.target.files[0]);
                          setNewCourseData({
                            data: data,
                            adaptFile: fileDataTemp,
                            adaptFileHindi: fileDataHindi,
                            skills: pillsDataSkills,
                            keyword: pillsDataKeyword,
                            newCourseID: newCourseID,
                            categoryData: categoryData,
                            pillsAddAudienceGroup: pillsAddAudienceGroup,
                            isPreviewAdaptFile: publishButtonAfterPreview,
                            addUserGroupList: addUserGroupList,
                            addUserGroupListCopy: addUserGroupListCopy,
                            deleteUserGroupList: deleteUserGroupList,
                          });
                          // setNewAdaptFile(fileData);
                          setNewCourseWizardState({
                            courseWizardOpen: true,
                            courseWizardStateOpen: 'main',
                            section: '',
                          });
                        }}
                        hidden={true}
                        multiple={false}
                        id={'chooseImage'}
                        disabled={disableImageLib}
                      />
                      <label htmlFor="chooseImage">
                        <div className={[styles.browse_text, styles.secondary_text].join(' ')}>
                          Choose Image from Local Device
                        </div>
                      </label>

                      <div className={styles.text}>or</div>

                      <div className={styles.browse_img_txt_wrapper}>
                        <div
                          className={styles.browse_text}
                          onClick={() => {
                            setImageData({
                              imageName: null,
                              imageDescription: null,
                              pillsData: null,
                            });
                            if (!disableImageLib) {
                              setThumbnailImage169(null);
                              setCourseMode('FROM_COURSE_WIZARD');
                              setLearningPath('');
                              router.push('/Contentmanagement');
                              // setOpenImageLib();

                              setNewCourseData({
                                data: data,
                                adaptFile: fileDataTemp,
                                adaptFileHindi: fileDataHindi,
                                skills: pillsDataSkills,
                                keyword: pillsDataKeyword,
                                newCourseID: newCourseID,
                                categoryData: categoryData,
                                pillsAddAudienceGroup: pillsAddAudienceGroup,
                                isPreviewAdaptFile: publishButtonAfterPreview,
                                addUserGroupList: addUserGroupList,
                                addUserGroupListCopy: addUserGroupListCopy,
                                deleteUserGroupList: deleteUserGroupList,
                              });
                              //setNewAdaptFile(fileData);
                              setNewCourseWizardState({
                                courseWizardOpen: true,
                                courseWizardStateOpen: 'main',
                                section: '',
                              });
                            }
                            if (uploadingFileButton || uploadingFileButtonHindi) {
                              disablehostingBanner();
                            }
                          }}
                        >
                          Choose Image from Image Library{' '}
                        </div>
                      </div>
                    </div>
                  ) : mainImageBackup && !isLoading ? (
                    <div className={[styles.thumbnail_inner_wrapper, ''].join(' ')}>
                      <div className={[styles.top_section, ' '].join(' ')}>
                        <div className={[styles.btn_wrapper_uaf, styles.margin_zero].join(' ')}>
                          <div
                            className={styles.txt}
                            onClick={() => {
                              if (!disableImageLib) {
                                setCourseMode('FROM_COURSE_WIZARD');
                                setLearningPath('');
                                router.push('/Contentmanagement');
                                // setOpenImageLib();
                                setNewCourseData({
                                  data: data,
                                  adaptFile: fileDataTemp,
                                  adaptFileHindi: fileDataHindi,
                                  skills: pillsDataSkills,
                                  keyword: pillsDataKeyword,
                                  newCourseID: newCourseID,
                                  categoryData: categoryData,

                                  pillsAddAudienceGroup: pillsAddAudienceGroup,
                                  isPreviewAdaptFile: publishButtonAfterPreview,
                                  addUserGroupList: addUserGroupList,
                                  addUserGroupListCopy: addUserGroupListCopy,
                                  deleteUserGroupList: deleteUserGroupList,
                                });
                                //setNewAdaptFile(fileData);
                                setNewCourseWizardState({
                                  courseWizardOpen: true,
                                  courseWizardStateOpen: 'main',
                                  section: '',
                                });
                              }
                              if (uploadingFileButton || uploadingFileButtonHindi) {
                                disablehostingBanner();
                              }
                            }}
                          >
                            Choose From Image Library
                          </div>
                          <div className={styles.book_icon}></div>
                        </div>
                        <div
                          className={styles.insturction_text_wrapper}
                          onClick={() => {
                            //setNewImageData(null);
                            if (!disableImageLib) {
                              setCourseMode('FROM_COURSE_WIZARD');
                              setLearningPath('');
                              uploadNewImagePath();
                              setNewCourseData({
                                data: data,
                                adaptFile: fileDataTemp,
                                adaptFileHindi: fileDataHindi,
                                skills: pillsDataSkills,
                                keyword: pillsDataKeyword,
                                newCourseID: newCourseID,
                                categoryData: categoryData,

                                pillsAddAudienceGroup: pillsAddAudienceGroup,
                                isPreviewAdaptFile: publishButtonAfterPreview,
                                addUserGroupList: addUserGroupList,
                                addUserGroupListCopy: addUserGroupListCopy,
                                deleteUserGroupList: deleteUserGroupList,
                              });
                              //setNewAdaptFile(fileData);
                              setNewCourseWizardState({
                                courseWizardOpen: true,
                                courseWizardStateOpen: 'main',
                                section: '',
                              });
                              setImageData({
                                imageName: null,
                                imageDescription: null,
                                pillsData: null,
                              });
                              setNewThumbnailImageData(mainImageBackup);
                              setNewImageData(null);
                              //setMainImageBackup(null);
                              setCroppedImageData(null);
                              setCroppedImage_16_9(null);
                              setCroppedImage_1_1(null);
                              setSelectedImage_16_9_Backup(null);
                              setSelectedImage_1_1_Backup(null);
                            }
                            if (uploadingFileButton || uploadingFileButtonHindi) {
                              disablehostingBanner();
                            }
                          }}
                        >
                          <div className={styles.info_text}>Upload New Image </div>
                          <div className={styles.left_arrow_icon}></div>
                        </div>
                      </div>
                      <div className={[styles.img_wrapper, ' '].join(' ')}>
                        <div className={styles.img}>
                          <img
                            // src={ImageDetail?.bannerImage ? ImageDetail.bannerImage : ""}
                            src={
                              //newThumbnailId?.length > 0 &&
                              thumbnailImage169?.img ? thumbnailImage169?.img : mainImageBackup
                            }
                            alt="Thambnail"
                            className={styles.selected_img}
                          />
                        </div>
                      </div>
                    </div>
                  ) : isLoading ? (
                    <LoadingShimmerCustom className={styles.pre_browse_wrapper} />
                  ) : null}
                  <div className={styles.tag_text}> 3MB max | .jpg, jpeg</div>
                </div>
                {/* Image Thumbnail Ends */}

                {/* Certificate & Achievement */}
                <div className={[styles.inr_wrapper].join(' ')}>
                  <div className={styles.title_wrapper}>
                    <div className={styles.title}>Achievements</div>
                    <div className={[styles.txt].join(' ')}>
                      Add an ‘Award of Completion’ to this Course to reward the ABO. They will get
                      an automatically generated Award with the Course Name, ABO Name, and Date of
                      Completion.
                      <div className={[styles.note_text, styles.weight_500].join(' ')}>
                        Note:{' '}
                        <span className={styles.note_text_italic}>
                          Award addition to a Course is only possible during creation of a Course.
                          It cannot be added or removed later.
                        </span>{' '}
                      </div>
                    </div>
                  </div>
                  <div className={styles.certificate_wrapper}>
                    <div className={styles.title}>Award</div>
                    {isLoading ? (
                      <div className={styles.checkbox_container}>
                        {' '}
                        <div className={styles.outerDiv}>
                          {' '}
                          <LoadingShimmerCustom className={styles.achievement_section} />
                        </div>{' '}
                        <div className={styles.checkbox_note}>
                          ABOs receive an Award for completing this Course.
                        </div>{' '}
                      </div>
                    ) : (
                      <div className={styles.checkbox_container}>
                        <CheckboxNew
                          isDisabled = {uploadingFileButton}
                          isChecked={data?.isCertificateAvailable}
                          handleCheckClick={() => {
                            if(uploadingFileButton) {
                              setNotificationData({
                                show: true,
                                type: 'FAILURE',
                                title: 'No Action can be taken during File Uploading',
                                description:
                                  'All the buttons are disabled when the file is uploading. Please wait for the file to upload to take any action.',
                              });
                              return
                            }
                            data && data?.isCertificateAvailable
                              ? handleDataFill('isCertificateAvailable', Boolean(false))
                              : handleDataFill('isCertificateAvailable', Boolean(true));
                          }}
                        />
                        <div className={styles.checkbox_note}>
                          ABOs receive an Award for completing this Course.
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* Certificate & Achievement */}

                {/* Download Creator only */}
                <div className={[styles.inr_wrapper].join(' ')}>
                  <div className={styles.title_wrapper}>
                    <div className={styles.title}>Downloads (Creators+ Only)</div>
                    <div className={[styles.txt].join(' ')}>
                      By checking below, you allow the ABOs to download the course and take it
                      Offline. Downloading is unavailable in the web version of Academy.
                    </div>
                  </div>
                  <div className={styles.certificate_wrapper}>
                    {isLoading ? (
                      <div className={styles.checkbox_container}>
                        {' '}
                        <div className={styles.outerDiv}>
                          {' '}
                          <LoadingShimmerCustom className={styles.achievement_section} />
                        </div>{' '}
                        <div className={styles.checkbox_note}>ABOs can download the Course.</div>{' '}
                      </div>
                    ) : (
                      <div className={styles.checkbox_container}>
                        <CheckboxNew
                          isDisabled={uploadingFileButton}
                          isChecked={data?.isDownloadable}
                          handleCheckClick={() => {
                            if(uploadingFileButton) {
                              setNotificationData({
                                show: true,
                                type: 'FAILURE',
                                title: 'No Action can be taken during File Uploading',
                                description:
                                  'All the buttons are disabled when the file is uploading. Please wait for the file to upload to take any action.',
                              });
                              return
                            }
                            data && data?.isDownloadable
                              ? handleDataFill('isDownloadable', Boolean(false))
                              : handleDataFill('isDownloadable', Boolean(true));
                          }}
                        />
                        <div className={styles.checkbox_note}>ABOs can download the Course.</div>
                      </div>
                    )}
                  </div>
                </div>
                {/* Download Creator only */}
              </div>
              {/* Information Ends */}
            </div>
          </div>
        </div>
      )}
      {/* Course Information */}

      {/* Translation */}
      {nextTranslation && (
        <>
          <TranslationLanguage
            isLp={false}
            learningPathId={query.id}
            // data={data}
            translationFor="course wizard"
            placeholders={{
              dropdown: 'Select Language',
              title: 'Enter Course Title',
              author: 'Enter Author/Speaker Name',
              description: 'Enter Course Description',
            }}
            previewAdaptFileCreateNewCourse={previewAdaptFileCreateNewCourse}
            courseData={data}
            newCourseID={newCourseID}
            isPrimaryCourseAdded={isPrimaryCourseAdded}
            setPrimaryCourseAdded={setPrimaryCourseAdded}
            showPrimaryNotification={showPrimaryNotification}
            setShowPrimaryNotification={setShowPrimaryNotification}
            setMultilangugalState={handleDataFill}
          />
        </>
      )}
      {/* Translation */}

      {/* User Group Targeting screen 3 started */}
      {nextUserGrp && (
        <div
          className={[
            styles.inner_wrapper,
            styles.category_wrapper,
            nextUserGrp ? styles.adapt_file : '',
            styles.preview_adapt,
            '',
          ].join(' ')}
        >
          <div className={styles.title_wrapper}>
            <div className={styles.title}>User Group Targeting</div>
            <div className={styles.txt}>
              Target this course to the User Groups mentioned below. If you do not want this course
              to be accessible to a particular audience group(s), you can remove them from the
              course accesibility list. You can specify the Course type by selecting from the
              options given below for each User Group.{' '}
              {remoteConfig?.user_group_country_targeting ? (
                <div>
                  {' '}
                  <b>
                    Note: At least one country must be selected in Country Availability to move
                    forward in the Create Course wizard.{' '}
                  </b>
                </div>
              ) : null}
            </div>
          </div>
          <div
            className={[styles.inr_wrapper, styles.audtarg_wrapper, 'col-md-12 col-lg-6', ''].join(
              ' ',
            )}
          >
            {remoteConfig?.user_group_country_targeting ? (
              <CountrySelection
                countryList={countryList}
                handleClearAll={handleCountryClearAll}
                handleSelectAll={handleCountrySelectAll}
                handleCountrySelect={handleCountrySelectA}
              ></CountrySelection>
            ) : null}

            <div className={[styles.form_field_ugm, styles.info_wrapper].join(' ')}>
              {
                <>
                  <div className={styles.card_list_wrapper}>
                    {addUserGroupList?.length == 0 && deleteUserGroupList?.length > 0 && (
                      <div className={styles.note_wrapper}>
                        <NoteCardComponent />
                      </div>
                    )}
                    {addUserGroupList?.length > 0 && (
                      <div className={styles.title}>User Groups with Course Access</div>
                    )}
                    <div className={styles.card_wrapper}>
                      {!isLoading &&
                        addUserGroupList?.map((element, index) => {
                          return (
                            <CardNewComponent
                              key={index}
                              ugCourseTitle={element?.title}
                              id={element?.id || element?.audGrpId}
                              isDisabled={disableStateUserManagement}
                              onHandleClickRadio={(ele, id) => {
                                handleDeleteUserTargetingGroups(
                                  {
                                    courseType: ele,
                                    id,
                                    title: element?.title,
                                    audienceGrpDescription:
                                      element?.audienceGrpDescription || element.description,
                                  },
                                  addUserGroupList,
                                );
                              }}
                              courseType={element?.courseType}
                              onButtonClick={() => {
                                handleDeleteUserGroups({
                                  courseType: element?.courseType,
                                  id: element?.id || element?.audGrpId,
                                  title: element?.title,
                                  audienceGrpDescription:
                                    element?.audienceGrpDescription || element.description,
                                });
                              }}
                              disableClickBanner={() => {
                                disableClickBanner();
                                if (uploadingFileButton || uploadingFileButtonHindi) {
                                  disablehostingBanner();
                                }
                              }}
                              bonusIcon={true}
                              spannerIcon={true}
                              audienceGrpDescription={element?.audienceGrpDescription}
                            />
                          );
                        })}
                      {isLoading ? getShimmerList(5) : null}
                    </div>
                  </div>
                </>
              }
              <div className={styles.line_break_ugm}></div>
              {
                <>
                  <div className={[styles.card_list_wrapper, styles.delete_ugm].join(' ')}>
                    <div className={styles.title}>User Groups with no Course Access</div>

                    {/* <div className={styles.card_wrapper}> */}
                    {!isLoading && deleteUserGroupList?.length > 0 ? (
                      deleteUserGroupList?.map((element, index) => {
                        if (true) {
                          return (
                            <CardNewComponent
                              key={index}
                              ugCourseTitle={element?.title}
                              withoutCourses={true}
                              onHandleClickRadio={(ele) => {
                                handleAddUserTargetingGroups({
                                  courseType: ele,
                                  id: element?.id,
                                }),
                                  deleteUserGroupList;
                              }}
                              courseType={element?.courseType}
                              id={element?.id || element?.audGrpId}
                              isDisabled={disableStateUserManagement}
                              onButtonClick={() => {
                                handleAddUserTargetingGroups({
                                  courseType: 'General visibility',
                                  id: element?.id || element?.audGrpId,
                                  title: element?.title,
                                  audienceGrpDescription:
                                    element?.audienceGrpDescription || element.description,
                                });
                              }}
                              disableClickBanner={() => {
                                disableClickBanner();
                                if (uploadingFileButton || uploadingFileButtonHindi) {
                                  disablehostingBanner();
                                }
                              }}
                              bonusIcon={true}
                              spannerIcon={true}
                              audienceGrpDescription={element?.audienceGrpDescription}
                            />
                          );
                        }
                      })
                    ) : (
                      <div className={styles.usergroup_withoutcourse_wrapper}>
                        <EmptyScreen></EmptyScreen>
                      </div>
                    )}
                    {/* </div> */}
                  </div>
                </>
              }
            </div>
            {/* Course runtime / Duration  */}
          </div>
        </div>
      )}
      {/* User Group Targeting screen 3 started */}

      {/* Category course wizard screen 4 started */}
      {nextState && (
        <div
          className={[
            styles.inner_wrapper,
            styles.category_wrapper,
            nextState ? styles.adapt_file : '',
            styles.preview_adapt,
            '',
          ].join(' ')}
        >
          <div
            className={[styles.prev_adapt_file_wrapper, 'hide'].join(' ')}
            onClick={async () => {
              if (
                (previewData ||
                  (data && data?.adaptFileName?.length > 0) ||
                  (fileData && fileData?.name?.length > 0)) &&
                !uploadingFileButton
              ) {
                setPreviewAdaptFileFlag(true);
                await previewAdaptFileCreateNewCourse(configData?.locale);
                //setOpenPreviewAdaptFile(true);
                //openInNewTab("url");
              }
            }}
          >
            <div
              className={
                (previewData ||
                  (data && data?.adaptFileName?.length > 0) ||
                  (fileData && fileData?.name?.length > 0)) &&
                !uploadingFileButton
                  ? styles.txt
                  : styles.txt_disable
              }
            >
              Preview Course File
            </div>
            <div
              className={[
                (previewData ||
                  (data && data?.adaptFileName?.length > 0) ||
                  (fileData && fileData?.name?.length > 0)) &&
                !uploadingFileButton
                  ? styles.adapt_file_icon
                  : styles.adapt_file_icon_disabled,
              ].join(' ')}
            ></div>
          </div>

          <div className={styles.row_wrapper}>
            {/* Info container  block */}
            <div className={styles.info_container}>
              <div
                className={[styles.inr_wrapper, styles.margin_pad_x, 'col-md-12 col-lg-6'].join(
                  ' ',
                )}
              >
                <div className={styles.title_wrapper}>
                  {/* <div className={styles.preview_tab_wrapper}>
                        <div className={styles.preview_icon}></div>
                        <div className={styles.note_txt}>
                          Please preview the Course file before publishing.
                        </div>
                      </div> */}

                  <div className={styles.title}>Category*</div>
                  <div className={styles.txt}>
                    All Courses must have a Category. Only one Category may be selected. Categories
                    are used in Explore by the ABO to filter through courses. They are also used by
                    the system when populating related courses.
                  </div>
                </div>
                <div className={[styles.form_field, styles.info_wrapper].join(' ')}>
                  {isLoading ? (
                    <LoadingShimmerCustom className={styles.search_list_wrapper_loading} />
                  ) : (
                    <SearchListComponent
                      list={categories}
                      varient="dropdown"
                      defaultInputData={categoryData ? categoryData : ''}
                      handlePillList={(title, dataWithTitle) => {
                        // setPillsData(data);
                        setCategoryData(title);
                        handleDataFill('category', dataWithTitle);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>

            {/* Info container 2nd block */}
            <div className={styles.info_container}>
              <div
                className={[styles.inr_wrapper, styles.audtarg_wrapper, 'col-md-12 col-lg-6'].join(
                  ' ',
                )}
              >
                <div className={styles.title_wrapper}>
                  <div className={styles.title}>Skills You Will Gain</div>
                  <div className={styles.txt}>
                    Choose from a pre-loaded list of Skills that aid the user in choosing which
                    Courses to take. Skills appear on the Course details drawer for front-end app
                    users. Skills are automatically translated into all language supported by your
                    market. You may select up to 5 skills to display on a Course.
                  </div>
                </div>
                <div
                  className={[styles.form_field, styles.info_wrapper, styles.margin_zero].join(' ')}
                >
                  {isLoading ? (
                    <LoadingShimmerCustom className={styles.search_list_wrapper_loading} />
                  ) : (
                    <div className={styles.input_wrapper} ref={skillsRef}>
                      <SearchListComponent
                        list={skills}
                        reference={skillsRef}
                        varient="textInput"
                        placeHolderText={
                          data?.skills?.length >= 5
                            ? 'All Skills Added'
                            : 'Enter Skills You Will Gain'
                        }
                        pillsLimit={5}
                        handlePillList={(data, dataWithID) => {
                          setPillsDataSkills(data);
                          handleDataFill('skills', dataWithID);
                        }}
                        defaultPillList={pillsDataSkills}
                        disabledInput={data?.skills?.length >= 5}
                      />
                      <div className={styles.count_num}>{`${
                        pillsDataSkills?.length ? pillsDataSkills?.length : '0'
                      }/5`}</div>
                    </div>
                  )}
                </div>
                {!isLoading && (
                  <div
                    className={[styles.pills_container, 'col-xl-12 col-lg-12 pull-left'].join(' ')}
                  >
                    <Pills
                      pillData={pillsDataSkills}
                      isClose={true}
                      isBold={false}
                      onClosePill={(item) => {
                        filterListById(
                          item.id,
                          pillsDataSkills,
                          setPillsDataSkills,
                          'skills',
                          handleDataFill,
                        );
                      }}
                    />
                  </div>
                )}
              </div>
              <div
                className={[styles.inr_wrapper, styles.audtarg_wrapper, 'col-md-12 col-lg-6'].join(
                  ' ',
                )}
              >
                <div className={styles.title_wrapper}>
                  <div className={styles.title}>Keyword Tagging</div>
                  <div className={styles.txt}>
                    Keyword Tags are used by the Academy to aid in search and filtering both for
                    front-end app users. These keywords do not appear on course detail drawers. Add
                    all relevant tags to the course.
                  </div>
                </div>
                <div
                  className={[styles.form_field, styles.info_wrapper, styles.margin_zero].join(' ')}
                  ref={keywordsRef}
                >
                  <div className={styles.label_wrapper}>
                    <div className={styles.label_text}>Add Tags</div>
                  </div>
                  {isLoading ? (
                    <LoadingShimmerCustom className={styles.search_list_wrapper_loading} />
                  ) : (
                    <SearchListComponent
                      list={keyword}
                      reference={keywordsRef}
                      placeHolderText="Add Keywords and Enter"
                      varient="textInput"
                      handlePillList={(data, dataWithID) => {
                        setPillsDataKeyword(data);
                        handleDataFill('keywords', dataWithID);
                      }}
                      pillsLimit={100}
                      defaultPillList={pillsDataKeyword}
                    />
                  )}
                </div>

                {!isLoading && (
                  <div
                    className={[styles.pills_container, 'col-xl-12 col-lg-12 pull-left'].join(' ')}
                  >
                    <Pills
                      pillData={pillsDataKeyword}
                      isClose={true}
                      isBold={false}
                      onClosePill={(item) => {
                        filterListById(
                          item.id,
                          pillsDataKeyword,
                          setPillsDataKeyword,
                          'keywords',
                          handleDataFill,
                        );
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Category course wizard screen 4 ended */}
    </>
  );
};

const mapStateToProps = (state) => ({
  countryList: state && state.uiState && state.uiState.countryList,
});

const mapDispatchToProps = (dispatch) => ({
  setCountryList: (options) => dispatch(setCountryList(options)),
  setLearningPath: (options) => dispatch(setLearningPath(options)),
});
export default React.memo(connect(mapStateToProps, mapDispatchToProps)(CreateWizard));
