import {combineReducers} from 'redux';

/**
 * Create reducers as required in reducers folder.
 * Add them to the rootReducer (combineReducer) with suitable state name.
 */
// import {Reducer1} from './Reducer1';
// import {Reducer2} from './Reducer2';
import {exampleReducer} from './exampleReducer';
import {imageCropReducer} from './imageCrop/imageCropReducer';
import {UIStateReducer} from './uiReducer';
import {newCourseReducer} from './newCourseReducer';
import {reducerImageLibrary} from '../reducers/reducerImageLibrary';
import {newLPReducer} from './learningPathReducer';
import { courseManagementReducer } from './courseManagementReducer';
import { learningPathManagementReducer } from './learningPathManagementReducer';
import { roasterListingReducer } from './roaster/roasterListingReducer';
import { userGroupManagementReducer } from './userGroupManagementReducer';

export const rootReducer = combineReducers({
  /**
   * Replace state1 with the name you want.
   * Replace Reducer1 with the matching reducer.
   */
  //   state1: Reducer1,
  //   state2: Reducer2,
  example: exampleReducer,
  croppedImage: imageCropReducer,
  uiState: UIStateReducer,
  courseState: newCourseReducer,
  ImageLibrary: reducerImageLibrary,
  LPState: newLPReducer,
  courseManagement: courseManagementReducer,
  learningPathManagement: learningPathManagementReducer,
  roasterListing: roasterListingReducer,
  userGroupManagement: userGroupManagementReducer,
});

export default rootReducer;
